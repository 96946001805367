import request from '../utils/request.js'

//左侧菜单
export function initLeft (query) {
  return request({
    url: '/website/indcategory/categoryList',
    method: 'get',
    params: query
  })
}

//根据左侧获取右侧
export function initRight (query) {
  return request({
    url: '/website/indcategory/secondAndThirdCategoryList',
    method: 'get',
    params: query
  })
}







