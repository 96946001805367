<template>
	<div class="container">

		<Header></Header>

		<div class="breadcrumbs wrap">
			<img src="../../assets/images/icon-home.png" alt=""  class="breadcrumbs-icon"/>
			<a href="/">首页</a> > 生活排行
		</div>
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right">
		  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
		  <el-breadcrumb-item>活动管理</el-breadcrumb-item>
		  <el-breadcrumb-item>活动列表</el-breadcrumb-item>
		  <el-breadcrumb-item>活动详情</el-breadcrumb-item>
		</el-breadcrumb> -->
		<div class="i-main wrap">
			
			<div class="class-top border-10">
				<img src="../../assets/images/class-bannerImg.png" alt="" />
			</div><!-- rank-m1 end -->
			
			<div class="rank-con clearfix mt-20">
				<div class="classify-left fl border-10 box-shadow">
				
					<ul class="classify-left-list">
						<li v-for="(item,index) in leftList" :key="index" :class="leftIndex == index?'active':''" @click="leftClick(index)">
							{{item.industryName}} 
						</li>
					</ul>
					
				</div><!-- classify-left end -->
				
				<div class="classify-right fr border-10">
					
					<div class="classify-right-box" v-for="(item,a) in 4" :key="a">
						<div class="classify-right-title">
							<h3><i></i>服装鞋帽</h3>
						</div>
						<div class="classify-right-ul">
							<ul>
								<li v-for="(item,b) in 20" :key="b">
									休闲男装
								</li>
							</ul>
						</div>
					</div><!-- classify-right-box end -->
					
				</div><!-- classify-right end -->
				
			</div><!-- rank-con end -->
			
		</div><!-- i-main end  -->
			
			
			
		<div class="i-m9-wrap mt-40">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->		
			
		<Footer></Footer>
		
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
		
		
	</div>
</template>

<script>
	import {initLeft,} from "../../api/classify.js";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "life",
		components: {
			Header,
			Footer
		},
		data() {
			return {
			
				leftIndex:0,
				leftList:[],
			
			
				
			};
		},
	
		created() {
			this.initPage();
		},

		computed: {},

		mounted() {
		

			// this.$AOS.init({

			// 	offset: 200,
			// 	duration: 600,
			// 	easing: 'ease-in-sine',
			// 	delay: 100

			// });
			// new this.$wow.WOW().init({
			// 	boxClass: 'wow',
			// 	animateClass: 'animate__animated',
			// 	offset: 0,
			// 	mobile: true,
			// 	live: true

			// });


		},

		methods: {
			initPage(){
				this.getLeft()
			
			},


			//左侧菜单
			getLeft(){
				initLeft({
					industryLevel:1
				}).then(res=>{
					console.log(res.data.data,'左侧菜单');
					this.leftList = res.data.data
				})
			},

			
			
			
			
			//旅游娱乐 1:旅游,2:品玩
			getLvyou(){
				lvyou({
					type:this.m6tabIndex
				}).then(res=>{
					console.log(res.data.data,'旅游娱乐');
					var dataCon = res.data.data
					var m6picList = dataCon.slice(0, 4);
					var m7list = dataCon.slice(-3);
					this.m6picList= m6picList
					this.m7list = m7list
					console.log(m7list,"旅游排行")
					
				})
			},


			//国家地区馆
			getGuojia(){
				guojia().then(res=>{
					console.log(res.data.data,'国家地区馆');
					var dataCon = res.data.data
					var m8list1 = dataCon.slice(0, 8);
					var m8list2 = dataCon.slice(-5);
					this.m8list1 = m8list1
					this.m8list2 = m8list2
				})
			},




			//跳转链接
			m8arrowLink() {
				this.$router.push({
					path: '/project-detail/project-detail'
				});
			},

				
			//左侧菜单 切换

			leftClick(index){
				// console.log(e.target.dataset.index)	
				// const index = e.target.dataset.index
				this.leftIndex = index
				
				// this.getHotPinpai()
			},

			


		},
	}
</script>

<style scoped>
	@import "../../assets/css/classify.css";
</style>